import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const PageInnerWrapper = styled.div`
  background: ${({ shade, theme }) =>
    shade ? theme.colors.tusk[shade] : theme.colors.tusk["020"]};
  padding: ${({ padding, theme }) =>
      padding !== "none" ? padding : theme.vPad}
    0;
`

const PageInner = ({ shade, padding, children }) => (
  <PageInnerWrapper shade={shade} padding={padding}>
    {children}
  </PageInnerWrapper>
)

PageInner.propTypes = {
  shade: PropTypes.oneOf(["000", "020", "040", "060", "080", "100"]),
  padding: PropTypes.string,
}

PageInner.defaultProps = {
  shade: "020",
  padding: "none",
}

export default PageInner
