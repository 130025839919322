import React from "react"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import styled from "styled-components"
import searchIcon from "../images/icons/search-dark.svg"
import Searchpage from "../components/Search/Searchpage"
import Grid from "../zzz/layout/grid"
import PageInner from "../zzz/layout/pageInner"

const Styled404SearchResults = styled.div`
  .recommend-searching {
    margin-top: 0.5em;
    margin-bottom: 30px;
  }
  div.searchText {
    max-width: 1240px;
    margin: 0 auto;
    padding-left: 20px;
    & h1 {
      margin: 40px 0;
    }
    @media only screen and (max-width: 768px) {
      width: 80%;
      padding-left: 0;
    }
  }
  div.ElasticSearchContainer {
    max-width: 1240px;
    position: relative;
    margin: 0 auto;
    padding: 0;
    .ElasticSearchInputField {
      width: 100%;
      padding: 0 40px;
      input {
        width: 100%;
        margin: 0 auto 40px auto;
        padding: 15px 45px 15px 15px;
        border-radius: 3px;
        border: 1px solid #3e4b5c;
        background-color: white;
        box-shadow: rgba(97, 97, 97, 0.25) 0px 13px 27px 0px;
        color: black;
        font-size: 16px;
        background-image: url(${searchIcon});
        background-repeat: no-repeat;
        background-position: 99% center;

        &::placeholder {
          color: rgba(0, 0, 0, 0.7);
        }
      }
      @media only screen and (max-width: 768px) {
        input {
          width: 80%;
          margin: 0 10% 60px 10%;
        }
      }
    }
    div.ElasticSearchHitsDropdown {
      width: 100%;
      padding: 0 1px;
      div.ElasticSearchHit {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 15px;
        border-bottom: 1px solid #3e4b5c;
        background-color: white;
        color: black;
        font-size: 16px;
        strong.ais-Highlight-highlighted {
          font-weight: bold;
        }
        img.ElasticSearchResultThumbnail {
          width: 64px;
          height: 64px;
        }
        img {
          margin-right: 15px;
        }
        &:hover {
          box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.35);
          position: relative;
          z-index: 101;
        }
      }
    }
    p.ElasticSearchNoHitsMsg {
      width: 100%;
      margin: 0 auto 60px auto;
      border-radius: 2px;
      border: 1px solid #3e4b5c;
      background-color: #e0e0e0;
      color: black;
      font-size: 16px;
      padding: 15px 45px 15px 15px;
      strong.no-match-query {
        font-weight: bold;
      }
      @media only screen and (max-width: 768px) {
        width: 80%;
      }
    }
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
`

const NotFoundPage = ({ location }) => {
  const post_id = -404
  const seo = {
    yoastTitle: "404: Not found",
    yoastDescription: "",
    yoastMeta: `[
      {"property":"og:title","content":"404: Not found"},
      {"property":"og:site_name","content":"The Mattress Warehouse"}
    ]`,
    yoastSchema: "",
    woocommerceSchema: "",
  }

  let pathname = location.pathname
  if (typeof window != "undefined") {
    pathname = window.location.pathname
  }

  const prod_path_arr = pathname.startsWith("/product/")
    ? pathname.split("/product/")
    : []
  const prod_path = prod_path_arr.length > 0 ? prod_path_arr[1] : false

  return (
    <Layout location={location}>
      <SEO
        title="404: Not found"
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <PageInner>
        <Grid>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          {prod_path && (
            <Styled404SearchResults>
              <p className="recommend-searching">
                You can search below to see if you can find the product you were
                looking for.
              </p>
              <Searchpage query={prod_path.replace(/(?:-|\/)/gi, " ")} />
            </Styled404SearchResults>
          )}
        </Grid>
      </PageInner>
    </Layout>
  )
}

export default NotFoundPage
